import { apis } from "service";

import { AxiosPromise, AxiosRequestConfig } from "axios";
const URL = process.env.REACT_APP_BACKEND_URL;

const API_URLS = {
  POST_LIST: `${URL}/admin/branch/list`,
  POST_LOGIN: `${URL}/admin/auth/login`,
  POST_LOGOUT: `${URL}/admin/auth/logout`
} as const;

export type PostListRequestType = Partial<{
  query: unknown;
  options: unknown;
  isCountOnly: unknown;
}>;

export type PostListResponseType = Partial<{
  status: string;
  message: string;
  data: any;
}>;

export const postList = (
  payload: AxiosRequestConfig<PostListRequestType>
): AxiosPromise<PostListResponseType> => apis.post(API_URLS.POST_LIST, payload);

export type PostLoginRequestType = Partial<{
  username: string;
  password: string;
}>;

export type PostLoginResponseType = Partial<{
  status: string;
  message: string;
  data: {
    id: number;
    username: string;
    email: string;
    name: unknown;
    userType: number;
    isActive: unknown;
    isDeleted: unknown;
    createdAt: string;
    updatedAt: string;
    addedBy: unknown;
    updatedBy: unknown;
    branch_name: unknown;
    mobile: unknown;
    conuntry_name: unknown;
    valid_dates: unknown;
    mobileNo: unknown;
    token: string;
  };
}>;

export const postLogin = (
  payload: AxiosRequestConfig<PostLoginRequestType>
): AxiosPromise<PostLoginResponseType> =>
  apis.post(API_URLS.POST_LOGIN, payload);
  export const postLogout = (payload: AxiosRequestConfig<any>): AxiosPromise<any> => apis.post(API_URLS.POST_LOGOUT, payload);
