import { DataTable } from "components/DataTable/DataTable";
import React from "react";
import { RoleColumnData } from "utils/TableColumnData";

export type Props = Partial<{
  errors: any[];
  mode: string;
}>;

export const RoleList: React.FC<Props> = ({ errors = [], mode = "" }) => {
  return (
    <>
      <DataTable
        apiUrl="/admin/adminrole/list"
        routeUrl="/master/adminrole"
        columnData={RoleColumnData}
        selectable={true}
        doAdd={true}
        doEdit={true}
        doDelete={true}
        doExport={true}
        paginate={true}
        perPage={10}
      />
    </>
  );
};
