import React, { useCallback, useEffect, useState } from "react";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { CreateCommanWay, GetCommanWay, ListCommanWay, UpdateCommanWay } from "API/commonApi";
import { AfDataType as ADT } from "utils/TableColumnData";
import { useFormik } from "formik";
import axios, { AxiosResponse } from "axios";
import UseToast from "utils/AfToast";
import { debounce } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { Switch } from "@headlessui/react";

export type AfTableProps = Partial<{
  className: string;
  columnData: any[];
  selectable: boolean;
  doAdd: boolean;
  doEdit: boolean;
  doDelete: boolean;
  doExport: boolean;
  paginate: boolean;
  addBtnUrl: string;
  validationSchema: any;
  initialValues: any;
  apiUrl: string;
  successNav: string;
  cancelNav: string;
  mode: string;
  adfilter?: string;
}>;

const GeneralAddForm: React.FC<AfTableProps> = ({
  columnData = [],
  doAdd = true,
  doEdit = true,
  doDelete = true,
  doExport = true,
  paginate = true,
  validationSchema,
  initialValues = {},
  apiUrl = "",
  successNav = "/",
  cancelNav = "/",
  mode = "View",
  adfilter = "",
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [dataMode, setDataMode] = useState<string>(mode);
  type OptionType = {
    value: number;
    label: string;
  };
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  const defaultOptions: any = [{ value: 0, label: "Loading..." }];
  const [nestedTables, setNestedTables] = useState<any>({});
  const [nestedSelectedOptions, setNestedSelectedOptions] = useState<any>({});
  const [isAdmin, setIsAdmin] = useState<any>(false);

  const [HI_check, set_HI_check] = useState<any>(false);
  const [EN_check, set_EN_check] = useState<any>(false);
  const [GJ_check, set_GJ_check] = useState<any>(false);

  useEffect(() => {
    console.log(dataMode);
    if (dataMode == "View" || dataMode == "Edit") {
      const id: string | undefined = params?.id;
      console.log(id);

      let includes_ = [];
      columnData.forEach((cd) => {
        if (cd.type == ADT.DB && cd.db_col != 'lang') {
          console.log("yesss", cd);
          includes_.push("_" + cd.key);
        }
      });

      ListCommanWay(apiUrl + "/list", { id: id }, { include: includes_ }).then((x) => {
        if (x.status == "SUCCESS") {
          x.data = x.data.data[0];
          console.log(x.data);
          if(apiUrl == "/admin/user"){
            let langs = x.data.langPerm.split(",") || [];
            if(langs.includes("HINDI")){set_HI_check(true)}
            if(langs.includes("ENGLISH")){set_EN_check(true)}
            if(langs.includes("GUJARATI")){set_GJ_check(true)}
          }
          let vals = {};
          columnData.forEach((cd) => {
            vals[cd.key] = x.data[cd.key];
            //setValues(vals);
            setFieldValue(cd.key, x.data[cd.key]);

            if (cd.type == ADT.DB) {
              console.log(cd.db);
              let n = { ...nestedSelectedOptions }; //rd["_" + cd.db_col][cd.dd_label]
              console.log(x.data, cd.key);
              if(cd.key == "lang"){
                n[cd.db] = { value: x.data[cd.key], label: x.data[cd.key] };
              }else{
                n[cd.db] = { value: x.data[cd.key], label: x?.data["_" + cd.db_col] ? x?.data["_" + cd.db_col][cd.dd_label] : "-" };
              }
              setNestedSelectedOptions(n);
            }
          });
        }
      });
    }
    let proms = [];

    let nestedApiData = {};
    columnData.forEach((cd) => {
      if (cd.type == ADT.DB) {
        proms.push(
          new Promise((res, rej) => {
            ListCommanWay(`/admin/${cd.db}/list`, {}, { paginate: 1000, order: [["id", "DESC"]] })
              .then((x) => {
                let data: OptionType[] = [];
                if (x.status == "SUCCESS") {
                  data = x.data.data.map((d) => {
                    return { value: d.id, label: d[cd.dd_label] };
                  });
                  nestedApiData[cd.db] = data;
                }
                res(1);
              })
              .catch((c) => {
                res(0);
              });
          })
        );
      }
    });

    Promise.all(proms).then((x) => {
      console.log("nestedApiData", nestedApiData, nestedSelectedOptions);
      setNestedTables(nestedApiData);
    });
  }, [dataMode]);

  const {
    mutate,
    isLoading: isloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(dataMode == "Add" ? CreateCommanWay : UpdateCommanWay, {
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        UseToast("Data Added Successfully", "success");
        successNavigate();
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });
  const { handleSubmit, handleBlur, values, handleChange, errors, setValues, setErrors, touched, dirty, setFieldValue } = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (values, { resetForm }) => {
      let _values: any = { ...values };
      let isFailed: any = false;
      console.log(apiUrl == "/admin/ads" && values.image && typeof values.image == "object");

      if (apiUrl == "/admin/ads" && values.image && typeof values.image == "object") {
        let formData = new FormData();
        formData.append("files", values.image);
        formData.append("folder", "image");
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const response2 = await axios.post(BASE_URL + "/admin/upload", formData, config);
        if (response2?.data?.uploadSuccess?.length > 0) {
          _values.image = response2.data.uploadSuccess[0]?.path;
          console.log(_values.image);
          if (!_values.image) {
            isFailed = response2.data.uploadFailed[0]?.error;
          }
        } else {
          delete _values.image;
          isFailed = response2.data.uploadFailed[0]?.error;
        }
      } else {
        delete _values.image;
      }
      console.log(apiUrl, _values);

      if (isFailed) {
        UseToast(isFailed, "error");
        return;
      }

      if (apiUrl == "/admin/user") {
        if (_values.password == "") {
          delete _values.password;
        }
        _values.username = _values.email;
        let langs = [];
        if(HI_check){langs.push("HINDI")}
        if(EN_check){langs.push("ENGLISH")}
        if(GJ_check){langs.push("GUJARATI")}
        _values.langPerm = langs.join(",");
      }
      if(apiUrl == "/admin/category" && !_values.lang){
        _values.lang = "ENGLISH";
      }
      mutate({
        url: apiUrl + (dataMode == "Add" ? "/create" : "/update/" + params?.id),
        data:
          apiUrl.indexOf("branch") !== -1 && _values["pincode"] !== undefined
            ? { ..._values, pincode: Number(_values["pincode"]) }
            : _values,
      });
    },
  });

  const successNavigate = useCallback(
    debounce(() => navigate(adfilter == "vid" ? successNav.replace("master", "master2") : successNav), 200),
    []
  );

  const onCancel = () => {
    navigate(adfilter == "vid" ? successNav.replace("master", "master2") : cancelNav);
  };

  return (
    <div className="p-5">
      <div className="text-right">
        {dataMode == "View" ? (
          <button
            onClick={() => {
              setDataMode("Edit");
            }}
            type="submit"
            className="px-4 py-2 text-sm rounded-md font-semibold bg-warning border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          >
            Edit Details
          </button>
        ) : (
          <button
            onClick={() => {
              setDataMode("View");
            }}
            type="submit"
            className="px-4 py-2 text-sm rounded-md font-semibold bg-warning border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          >
            Cancel Edit
          </button>
        )}
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <div className="grid grid-cols-3 gap-4">
          {columnData.map((c) => {
            if (c.add == true) {
              let isDisabled = true;
              if ((dataMode == "Add" && c.add) || (dataMode == "Edit" && c.edit)) {
                isDisabled = false;
              }
              switch (c.type) {
                case ADT.NUMBER:
                  return (
                    <div key={"general_" + c.key}>
                      <label htmlFor={"general_" + c.key} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        {c.label}
                      </label>
                      <input
                        type="number"
                        id={"general_" + c.key}
                        className={`${
                          isDisabled ? "bg-gray-300" : ""
                        } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                        placeholder={c.label}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name={c.key}
                        value={values[c.key]}
                        disabled={isDisabled}
                      />

                      <p className="mt-21 text-sm text-red-600">{touched[c.key] && Boolean(errors[c.key]) ? errors[c.key] : ""}</p>
                    </div>
                  );
                  break;
                case ADT.TEXT:
                  return (
                    <div key={"general_" + c.key}>
                      <label htmlFor={"general_" + c.key} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        {c.label}
                      </label>
                      <input
                        type="text"
                        id={"general_" + c.key}
                        className={`${
                          isDisabled ? "bg-gray-300" : ""
                        } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                        placeholder={c.label}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name={c.key}
                        value={values[c.key]}
                        disabled={isDisabled}
                      />

                      <p className="mt-21 text-sm text-red-600">{touched[c.key] && Boolean(errors[c.key]) ? errors[c.key] : ""}</p>
                    </div>
                  );
                  break;
                case ADT.DB:
                  return  (
                    
                    <div key={"general_" + c.key}>
                      <label htmlFor={"general_" + c.key} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        {c.label}
                      </label>

                      <Select
                        key={"select-" + (nestedTables[c.db] ? nestedTables[c.db].length : "undef")}
                        className={`${
                          isDisabled ? "bg-gray-300" : ""
                        } bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
                        id={"general_" + c.key}
                        options={nestedTables[c.db] ? nestedTables[c.db] : defaultOptions}
                        onChange={(el) => {
                          setFieldValue(c.key, el.value);
                          setNestedSelectedOptions(el);
                        }}
                        isDisabled={isDisabled}
                        name={c.key}
                        value={nestedSelectedOptions[c.db]}
                      />

                      <p className="mt-21 text-sm text-red-600">{touched[c.key] && Boolean(errors[c.key]) ? errors[c.key] : ""}</p>
                    </div>
                  );
                  break;
                case ADT.TEXTAREA:
                  break;
                case ADT.DATE:
                  return (
                    <div key={"general_" + c.key}>
                      <label htmlFor={"general_" + c.key} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        {c.label}
                      </label>
                      <input
                        type="date"
                        id={"general_" + c.key}
                        className={`${
                          isDisabled ? "bg-gray-300" : ""
                        } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                        placeholder={c.label}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name={c.key}
                        value={values[c.key]}
                        disabled={isDisabled}
                      />

                      <p className="mt-21 text-sm text-red-600">{touched[c.key] && Boolean(errors[c.key]) ? errors[c.key] : ""}</p>
                    </div>
                  );
                  break;
                case ADT.FILE:
                  return (
                    <div key={"general_" + c.key}>
                      <label htmlFor={"general_" + c.key} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        {c.label}
                      </label>
                      <input
                        type="file"
                        id={"general_" + c.key}
                        className={`${
                          isDisabled ? "bg-gray-300" : ""
                        } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                        placeholder={c.label}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(c.key, e.target.files[0]);
                        }}
                        name={c.key}
                        disabled={isDisabled}
                      />

                      <p className="mt-21 text-sm text-red-600">{touched[c.key] && Boolean(errors[c.key]) ? errors[c.key] : ""}</p>
                    </div>
                  );
                  break;
                case ADT.DD:
                  let selectedOpt = undefined;
                  if (dataMode != "Add" && values[c.key]) {
                    (adfilter == "vid" ? c.optVid : c.opt).forEach((o) => {
                      if (o.value == values[c.key]) {
                        selectedOpt = o;
                      }
                    });
                  }
                  return (
                    <div key={"general_" + c.key}>
                      <label htmlFor={"general_" + c.key} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        {c.label}
                      </label>

                      <Select
                        key={"dd-" + c.key}
                        className={`${
                          isDisabled ? "bg-gray-300" : ""
                        } bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
                        id={"general_" + c.key}
                        options={adfilter == "vid" ? c.optVid : c.opt}
                        onChange={(el) => {
                          setFieldValue(c.key, el.value);
                          setNestedSelectedOptions(el);
                        }}
                        isDisabled={isDisabled}
                        name={c.key}
                        value={selectedOpt}
                      />

                      <p className="mt-21 text-sm text-red-600">{touched[c.key] && Boolean(errors[c.key]) ? errors[c.key] : ""}</p>
                    </div>
                  );
                  break;
                default:
                  return <></>;
                  break;
              }
            }
          })}
        </div>

        {apiUrl == "/admin/user" && <div className="grid grid-cols-1 mt-4 gap-4" >
            <div className="flex items-center gap-2">
              <Switch
                checked={HI_check}
                onChange={set_HI_check}
                id="HI_check"
                disabled={dataMode != "Edit" && dataMode != "Add"}
                className={`${HI_check ? "bg-blue-600" : "bg-gray-200"} relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only"></span>
                <span
                  className={`${
                    HI_check ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
              <label htmlFor="HI_check">Hindi</label>
            </div>

            <div className="flex items-center gap-2">
              <Switch
                checked={EN_check}
                onChange={set_EN_check}
                id="EN_check"
                disabled={dataMode != "Edit" && dataMode != "Add"}
                className={`${EN_check ? "bg-blue-600" : "bg-gray-200"} relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only"></span>
                <span
                  className={`${
                    EN_check ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
              <label htmlFor="EN_check">English</label>
            </div>

            <div className="flex items-center gap-2">
              <Switch
                checked={GJ_check}
                onChange={set_GJ_check}
                id="GJ_check"
                disabled={dataMode != "Edit" && dataMode != "Add"}
                className={`${GJ_check ? "bg-blue-600" : "bg-gray-200"} relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only"></span>
                <span
                  className={`${
                    GJ_check ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
              <label htmlFor="GJ_check">Gujrati</label>
            </div>
        </div>}

        {dataMode != "View" && (
          <div className="inline-flex rounded-md overflow-hidden shadow-sm mb-1 mt-4" role="group">
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
            >
              {dataMode}
            </button>
            <button
              type="button"
              onClick={onCancel}
              className="px-4 py-2 text-sm font-medium text-white bg-gray-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
            >
              Back to List
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export { GeneralAddForm };
