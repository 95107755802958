import React, { lazy, memo, Suspense } from "react";
import { Navigate, Route, Router, Routes } from "react-router-dom";
import { lazyImport } from "../../utils/lazyImport";

import PrivateRouter, { PrivateRouteProps } from "../../Routes/RootNavigator/PrivateRouter";
import Spinner from "../../utils/Spinner";
import { PublicRouteProps } from "../../Routes/RootNavigator/PublicRouter";
import GenericTableList from "pages/GenericTable/GenericTableList";
import GenericTableDetails from "pages/GenericTable/GenericTableDetails";
import GenericTableCreate from "pages/GenericTable/GenericTableCreate";
import NewsCreate from "pages/News/NewsCreate";
import NewsList from "pages/News/NewsList";
import { RoleList } from "pages/Role/RoleList";
import { RoleAddEdit } from "pages/Role/RoleAddEdit";

const { NotFound } = lazyImport(() => import("pages/NotFound"), "NotFound");

const { Dashboard } = lazyImport(() => import("pages/Dashboard"), "Dashboard");

const AppContent = () => {
  const GenericTables = ["category", "subcategory", "user"];
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Spinner />
        </div>
      }
    >
      <Routes>
        <Route path="/dashboard">
          <Route index element={<PrivateRouter {...defaultPrivateRouteProps} outlet={<Dashboard />} />} />
        </Route>
        <Route path="*" element={<Navigate to="/dashboard" />} />

        {GenericTables.map((tbl) => {
          return (
            <Route path={`/master/${tbl}`} key={"route-" + tbl}>
              <Route
                path="list"
                element={<PrivateRouter {...defaultPrivateRouteProps} outlet={<GenericTableList tableName={tbl} />} />}
              />
              {/* <Route path="add" element={<CreateAdmin />} /> */}
              <Route path="list/:id" element={<GenericTableDetails tableName={tbl} />} />
              <Route path="add" element={<GenericTableCreate tableName={tbl} />} />
            </Route>
          );
        })}

        <Route path={`/master/ads`}>
          <Route
            path="list"
            element={<PrivateRouter {...defaultPrivateRouteProps} outlet={<GenericTableList filter="ads" tableName={"ads"} />} />}
          />
          {/* <Route path="add" element={<CreateAdmin />} /> */}
          <Route path="list/:id" element={<GenericTableDetails filter="ads" tableName={"ads"} />} />
          <Route path="add" element={<GenericTableCreate filter="ads" tableName={"ads"} />} />
        </Route>

        <Route path={`/master2/ads`}>
          <Route
            path="list"
            element={<PrivateRouter {...defaultPrivateRouteProps} outlet={<GenericTableList filter="vid" tableName={"ads"} />} />}
          />
          {/* <Route path="add" element={<CreateAdmin />} /> */}
          <Route path="list/:id" element={<GenericTableDetails filter="vid" tableName={"ads"} />} />
          <Route path="add" element={<GenericTableCreate filter="vid" tableName={"ads"} />} />
        </Route>

        <Route path={`/master/admin`} key={"route-news"}>
          <Route path="list" element={<PrivateRouter {...defaultPrivateRouteProps} outlet={<GenericTableList filter="admin" tableName={"user"} />} />} />
          {/* <Route path="add" element={<CreateAdmin />} /> */}
          <Route path="list/:id" element={<NewsCreate mode="view" />} />
          <Route path="add" element={<NewsCreate mode="add" />} />
        </Route>


        <Route path={`/master/news`} key={"route-news"}>
          <Route path="list" element={<PrivateRouter {...defaultPrivateRouteProps} outlet={<GenericTableList tableName={"news"} />} />} />
          {/* <Route path="add" element={<CreateAdmin />} /> */}
          <Route path="list/:id" element={<NewsCreate mode="view" />} />
          <Route path="add" element={<NewsCreate mode="add" />} />
        </Route>


        <Route path={`/master/adminrole`} key={"route-role"}>
          <Route path="list" element={<PrivateRouter {...defaultPrivateRouteProps} outlet={<RoleList />} />} />
          {/* <Route path="add" element={<CreateAdmin />} /> */}
          <Route path="list/:id" element={<RoleAddEdit mode="View" />} />
          <Route path="add" element={<RoleAddEdit mode="Add" />} />
        </Route>

      </Routes>
    </Suspense>
  );
};

export default memo(AppContent);

const defaultPublicRouteProps: Omit<PublicRouteProps, "outlet"> = {
  authenticatedPath: "/",
};

const defaultPrivateRouteProps: Omit<PrivateRouteProps, "outlet"> = {
  authenticationPath: "/login",
};
