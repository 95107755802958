import { object, string } from "yup";

export enum AfDataType {
  NUMBER,
  TEXT,
  TEXTAREA,
  DATETIME,
  DATE,
  FILE,
  DB,
  DD,
  VIEW,
  BOOLEAN,
  SELECT,
  EDIT,
  BUTTON,
  CUSTOM,
}
const ADT = AfDataType;

/*const getLangOpt = () => {
  try{
    let userObj = JSON.parse(localStorage.auth).state.user;
    console.log(userObj.langPerm)
    return [
      { value: "ENGLISH", label: "ENGLISH" },
      { value: "HINDI", label: "HINDI" },
      { value: "GUJARATI", label: "GUJARATI" },
    ];
  }catch(c){
    return [
      { value: "ENGLISH", label: "ENGLISH" },
      { value: "HINDI", label: "HINDI" },
      { value: "GUJARATI", label: "GUJARATI" },
    ];
  }
};*/

const viewActionColumn = {
  label: "Action",
  key: "id",
  render: (_) => {
    return `<a>${_}</a>`;
  },
  type: ADT.VIEW,
  view: true,
  add: false,
  edit: false,
  filter: false,
  sort: false,
};

/* === Start === */
export const GenericColumnData = {
  category: {
    tableColumnData: [
      {
        label: "Id",
        key: "id",
        type: ADT.TEXT,
        view: true,
        add: false,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Name",
        key: "name",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Slug",
        key: "slug",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Language",
        key: "lang",
        type: ADT.DB,
        db: "lang",
        db_col: "lang",
        dd_label: "label",
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },

      viewActionColumn,
    ],
    validationSchema: object().shape({
      name: string().required("Required Field"),
      slug: string().required("Required Field"),
    }),
    initialValues: {
      name: "",
      slug: "",
    },
  },
  subcategory: {
    tableColumnData: [
      {
        label: "Id",
        key: "id",
        type: ADT.TEXT,
        view: true,
        add: false,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Name",
        key: "name",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Slug",
        key: "slug",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Parent Category",
        key: "category",
        type: ADT.DB,
        db: "category",
        db_col: "category",
        dd_label: "name",
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      viewActionColumn,
    ],
    validationSchema: object().shape({
      name: string().required("Required Field"),
      slug: string().required("Required Field"),
      category: string().required("Required Field"),
    }),
    initialValues: {
      name: "",
      slug: "",
      category: "",
    },
  },
  ads: {
    tableColumnData: [
      {
        label: "Id",
        key: "id",
        type: ADT.TEXT,
        view: true,
        add: false,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Title",
        key: "positionName",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "position",
        key: "variableName",
        type: ADT.DD,
        db: "variableName",
        dd_label: "name",
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
        opt: [
          { value: "left_in", label: "Left Inner" },
          { value: "right_in", label: "Right Inner" },
          { value: "bottom", label: "News Bottom" },
        ],
        optVid: [
          { value: "before_vid", label: "Before Video" },
          { value: "after_vid", label: "After Video" },
        ],
      },
      {
        label: "From",
        key: "fromDt",
        type: ADT.DATE,
        view: true,
        add: true,
        edit: true,
        filter: false,
        sort: false,
      },
      {
        label: "To",
        key: "toDt",
        type: ADT.DATE,
        view: true,
        add: true,
        edit: true,
        filter: false,
        sort: false,
      },
      {
        label: "News",
        key: "news",
        type: ADT.DB,
        db: "news",
        db_col: "news",
        dd_label: "title",
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Link",
        key: "link",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: false,
        sort: false,
      },
      {
        label: "File",
        key: "image",
        type: ADT.FILE,
        view: false,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      viewActionColumn,
    ],
  },
  news: {
    tableColumnData: [
      {
        label: "Id",
        key: "id",
        type: ADT.TEXT,
        view: true,
        add: false,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Title",
        key: "title",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Language",
        key: "lang",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Category",
        key: "subCategory",
        type: ADT.DB,
        db: "subcategory",
        db_col: "subCategory",
        dd_label: "name",
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Main Category",
        key: "mainCategory",
        type: ADT.DB,
        db: "category",
        db_col: "mainCategory",
        dd_label: "name",
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Is Season ?",
        key: "isSeason",
        type: ADT.BOOLEAN,
        view: true,
        add: false,
        edit: false,
        filter: false,
        sort: false,
      },
      viewActionColumn,
    ],
  },
  user: {
    tableColumnData: [
      {
        label: "Id",
        key: "id",
        type: ADT.TEXT,
        view: true,
        add: false,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Email",
        key: "email",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Password",
        key: "password",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "First Name",
        key: "firstName",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Last Name",
        key: "lastName",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Gender",
        key: "gender",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "DoB",
        key: "dob",
        type: ADT.DATE,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Mobile",
        key: "mobile",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Pincode",
        key: "pincode",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "User Type",
        key: "userType",
        type: ADT.DD,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
        render: (_) => {
          return _ == 1 ? "Admin" : "User";
        },
        opt: [
          { value: 1, label: "Admin" },
          { value: 3, label: "User" },
        ],
      },

      {
        label: "Admin Role",
        key: "adminRole",
        type: ADT.DB,
        db: "adminrole",
        db_col: "adminRole",
        dd_label: "name",
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },

      viewActionColumn,
    ],
    validationSchema: object().shape({
      email: string().required("Required Field"),
      firstName: string().required("Required Field"),
      lastName: string().required("Required Field"),
      gender: string().required("Required Field"),
      dob: string().required("Required Field"),
      mobile: string().required("Required Field"),
      pincode: string().required("Required Field"),
      userType: string().required("Required Field"),
    }),
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      gender: "",
      dob: "",
      mobile: "",
      pincode: "",
      userType: "",
      password: "",
    },
  },
};
/* === --- === */

/* === Start === */
export const BrachColumnData = [
  {
    label: "Id",
    key: "id",
    type: ADT.TEXT,
    view: true,
    add: false,
    edit: true,
    filter: true,
    sort: true,
  },
  {
    label: "Name",
    key: "branch_name",
    type: ADT.TEXT,
    view: true,
    add: true,
    edit: true,
    filter: true,
    sort: true,
  },
  {
    label: "Addr",
    key: "address",
    type: ADT.TEXT,
    view: true,
    add: true,
    edit: true,
    filter: true,
    sort: true,
  },
  {
    label: "Pincode",
    key: "pincode",
    type: ADT.TEXT,
    view: true,
    add: true,
    edit: true,
    filter: true,
    sort: true,
  },
  viewActionColumn,
];
export const BranchValidationSchema = object().shape({
  branch_name: string().required("Required Field"),
  address: string().required("Required Field"),
  pincode: string()
    .required("Required Field")
    .matches(/^[1-9][0-9]{5}$/, "Invalid Pincode"),
});
export const BranchInitialValues = {
  branch_name: "",
  address: "",
  pincode: "",
};
/* === --- === */

export const RoleColumnData = [
  {
    label: "Id",
    key: "id",
    type: ADT.TEXT,
    view: true,
    add: false,
    edit: true,
    filter: true,
    sort: true,
  },
  {
    label: "Name",
    key: "name",
    type: ADT.TEXT,
    view: true,
    add: true,
    edit: true,
    filter: true,
    sort: true,
  },
  viewActionColumn,
];
export const RoleValidationSchema = object().shape({
  name: string().required("Required Field"),
});
export const RoleInitialValues = {
  name: "",
};
