import React from "react";
import { GenericColumnData } from "utils/TableColumnData";
import { GeneralAddForm } from "components/GeneralAddForm/GeneralAddForm";
import { useLocation } from "react-router-dom";

const GenericTableDetails: React.FC<any> = (props) => {
  const location = useLocation();
  const { state }: any = location;
  console.log(state);

  return (
    <>
      <GeneralAddForm
        successNav={`/master/${props.tableName}/list`}
        cancelNav={`/master/${props.tableName}/list`}
        apiUrl={`/admin/${props.tableName}`}
        mode={state?.mode ? state?.mode : "View"}
        columnData={GenericColumnData[props.tableName].tableColumnData}
        validationSchema={GenericColumnData[props.tableName].validationSchema}
        initialValues={GenericColumnData[props.tableName].initialValues}
        selectable={true}
        doAdd={true}
        doEdit={true}
        doDelete={true}
        doExport={true}
        paginate={true}
        adfilter={props.filter}
      />
    </>
  );
};

export default GenericTableDetails;
