import React, { useEffect, useState } from "react";
import { ErrorMessage } from "../../components/ErrorMessage";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { DeleteCommanWay, GetCommanWay, GetCommanWayMutate, ListCommanWay } from "API/commonApi";
import { Link } from "react-router-dom";
import { AfDataType } from "utils/TableColumnData";
import { AxiosResponse } from "axios";
import UseToast from "utils/AfToast";
import Swal from "sweetalert2";
import { debounce } from "lodash";
import Spinner from "utils/Spinner";
import { Input } from "components/Input";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import moment from "moment";

export type AfTableProps = Partial<{
  onColor: string;
  offColor: string;
  onHandleColor: string;
  offHandleColor: string;
  value: string | boolean;
  className: string;
  checkedIcon: React.ReactNode;
  uncheckedIcon: React.ReactNode;
  errors: any[];
  onChange: Function;

  columnData: any[];
  selectable: boolean;
  doAdd: boolean;
  doEdit: boolean;
  doDelete: boolean;
  doExport: boolean;
  paginate: boolean;
  routeUrl: string;
  apiUrl: string;
  perPage: number;
  adfilter?: string;
}>;

const DataTable: React.FC<AfTableProps> = ({
  onChange,

  onColor = "",
  offColor = "",
  onHandleColor = "",
  offHandleColor = "",
  value = false,
  className = "",
  checkedIcon = <></>,
  uncheckedIcon = <></>,
  errors = [],

  columnData = [],
  doAdd = true,
  doEdit = true,
  doDelete = true,
  doExport = true,
  paginate = true,
  routeUrl = "/",
  apiUrl = "/",
  perPage = 25,
  adfilter = "",
}) => {
  const [tableData, setTableData] = useState<any>([]);

  const [currentPage, serCurrentPage] = useState<number>(1);
  //const [perPage, setPerPageCount] = useState<number>(25);
  const [pageCount, setPageCount] = useState<number>(1);
  const [itemCount, setItemCount] = useState<number>(1);

  const [searchTerm, setSearchTerm] = useState<string>("");

  //const [includes, setIncludes] = useState<any>([]);
  let incAry = [];
  columnData.forEach((c) => {
    if (c.db_col && c.db_col != 'lang') {
      incAry.push("_" + c.db_col);
    }
  });
  //setIncludes(incAry);
  useEffect(() => {
    console.log("currentPage change", currentPage);
    setTimeout(() => {
      refetch();
    }, 100);
  }, [currentPage, apiUrl, adfilter]);

  const { error, isFetching, isError, isFetched, isLoading, refetch } = useQuery(
    [apiUrl.replaceAll("/", "_")],
    () =>
      ListCommanWay(
        apiUrl,
        adfilter == "ads"
          ? { variableName: ["bottom", "left_in", "right_in"], positionName: { $like: `%${searchTerm}%` } }
          : adfilter == "vid"
          ? { variableName: ["before_vid", "after_vid"], positionName: { $like: `%${searchTerm}%` } }
          : adfilter == "admin"
          ? { userType: 1, $or: [{ firstName: { $like: `%${searchTerm}%` } }, { lastName: { $like: `%${searchTerm}%` } }] }
          : apiUrl == "/admin/user/list"
          ? { userType: 3, $or: [{ firstName: { $like: `%${searchTerm}%` } }, { lastName: { $like: `%${searchTerm}%` } }] }
          : {},
        apiUrl.indexOf("state") !== -1 || apiUrl.indexOf("customer") !== -1
          ? {}
          : {
              include: incAry,
              paginate: paginate ? perPage : 100000,
              page: currentPage,
              order: [["id", "DESC"]],
            }
      ),
    {
      keepPreviousData: true,
      enabled: false,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          setTableData(data.data.data);
          //serCurrentPage(data.data.paginator?.currentPage);
          //setPerPageCount(data.data.paginator?.perPage);
          setPageCount(data.data.paginator?.pageCount);
          setItemCount(data.data.paginator?.itemCount);
        }
      },
    }
  );

  const handleDeleteItem = (id: any) => {
    Swal.fire({
      title: "Are you sure!",
      icon: "warning",
      confirmButtonText: "Yes",
      confirmButtonColor: "red",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        mutate({
          url: "/admin/" + apiUrl.split("/")[2] + "/delete/" + id,
          data: {},
        });
      }
    });
  };

  const handleExport = (id: any) => {
    exportMutate({
      url: "/admin/" + apiUrl.split("/")[2] + "/" + id,
    });
  };

  const {
    mutate: exportMutate,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(GetCommanWayMutate, {
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        console.log(data);
        let dataSet1 = [
          {
            Title: data.data.positionName,
            From: moment(data.data.fromDt).format("DD-MM-YYYY"),
            To: moment(data.data.toDt).format("DD-MM-YYYY"),
            link: data.data.link || "-",
            View: data.data.views || 0,
            Click: data.data.extra || 0,
          },
        ];
        console.log(dataSet1);

        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        const ws = XLSX.utils.json_to_sheet(dataSet1);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data_ = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data_, data.data.positionName + fileExtension);

        UseToast("Data Exported Successfully", "success");
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const {
    mutate,
    isLoading: isloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(DeleteCommanWay, {
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        UseToast("Data Deleted Successfully", "success");
        refetch();
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const renderSwitch = (param) => {
    switch (param) {
      case "foo":
        return "bar";
      default:
        return "foo";
    }
  };

  return (
    <div className="p-5">
      {isFetching && <Spinner />}
      <div className="inline-flex rounded-md overflow-hidden shadow-sm mb-1 gap-4" role="group">
        <Link
          to={adfilter == "vid" ? "/master2/ads/add" : routeUrl + "/add"}
          type="button"
          className="px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
        >
          Add
        </Link>

        {(apiUrl == "/admin/user/list" || apiUrl == "/admin/ads/list") && (
          <div className="flex">
            <div className="border">
              <Input
                type="text"
                className="p-1"
                placeholder="Enter Search Query"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e);
                }}
              />
            </div>
            <button
              className="text-white bg-primary px-2"
              onClick={() => {
                refetch();
              }}
            >
              Search
            </button>
          </div>
        )}
      </div>
      <div className="flex overflow-auto">
        <table className="w-full text-sm text-left text-gray-500 border-r border-t">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              {columnData.map((c) => {
                return (
                  c.view && (
                    <th scope="col" className="px-6 py-3 border-l border-b" key={c.label}>
                      <div className="flex items-center">
                        {c.label}
                        {c.sort && false && (
                          <a href="#">
                            <svg
                              className="w-3 h-3 ml-1.5"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                            </svg>
                          </a>
                        )}
                      </div>
                      {c.filter && false && (
                        <>
                          {" "}
                          <input
                            type="text"
                            className="block p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                            placeholder="Search"
                          />
                        </>
                      )}
                    </th>
                  )
                );
              })}
            </tr>
          </thead>

          <tbody>
            {tableData.map((rd, j) => {
              return (
                <tr className="bg-white border-b" key={"tbl-tr-" + j}>
                  {columnData.map((cd, i) => {
                    return (
                      cd.view && (
                        <td className="border-l px-2 py-1" key={"tbl-td-" + i}>
                          {cd.type == AfDataType.VIEW ? (
                            <>
                              <Link
                                className="text-dark1"
                                to={adfilter == "vid" ? `/master2/ads/list/${rd[cd.key]}` : `${routeUrl}/list/${rd[cd.key]}`}
                              >
                                View
                              </Link>
                              <Link
                                className="text-dark1 ml-4"
                                to={{
                                  pathname: adfilter == "vid" ? `/master2/ads/list/${rd[cd.key]}` : `${routeUrl}/list/${rd[cd.key]}`,
                                }}
                                state={{ mode: "Edit" }}
                              >
                                Edit
                              </Link>
                              <button
                                className="text-danger ml-4"
                                onClick={() => {
                                  handleDeleteItem(rd[cd.key]);
                                }}
                              >
                                Delete
                              </button>
                              {apiUrl == "/admin/ads/list" && (
                                <button
                                  className="text-primary ml-4"
                                  onClick={() => {
                                    handleExport(rd[cd.key]);
                                  }}
                                >
                                  Export
                                </button>
                              )}
                            </>
                          ) : (cd.type == AfDataType.DB && cd.db_col != 'lang') ? (
                            <>{rd["_" + cd.db_col] ? rd["_" + cd.db_col][cd.dd_label] : "-"}</>
                          ) : cd.type == AfDataType.BOOLEAN ? (
                            <>{rd[cd.key] === true ? "True" : "False"}</>
                          ) : cd.render ? (
                            cd.render(rd[cd.key])
                          ) : (
                            <>{rd[cd.key]}</>
                          )}
                        </td>
                      )
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <nav className="flex items-center justify-between pt-4" aria-label="Table navigation">
        <span className="text-sm font-normal text-gray-500">
          Total: <span className="font-semibold text-gray-900">{itemCount}</span>
        </span>
        <ul className="inline-flex -space-x-px text-sm h-8">
          <li>
            <div
              onClick={() => serCurrentPage((prevData) => (prevData > 1 ? prevData - 1 : prevData))}
              className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 "
            >
              Previous
            </div>
          </li>
          {Array.from(Array(pageCount)).map((_p, p) => {
            //console.log("======> p", p, currentPage);
            return (
              <li key={"paginate-" + p}>
                <a
                  onClick={() => serCurrentPage(p + 1)}
                  href="#"
                  aria-current="page"
                  className={`flex items-center justify-center px-3 h-8 border-gray-300 border ${
                    p + 1 == currentPage && "text-blue-600 bg-blue-50"
                  }`}
                >
                  {p + 1}
                </a>
              </li>
            );
          })}
          <li>
            <div
              // href="#"
              onClick={() => serCurrentPage((prevData) => (prevData < pageCount ? prevData + 1 : prevData))}
              className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 "
            >
              Next
            </div>
          </li>
        </ul>
      </nav>
      <ErrorMessage errors={errors} />
    </div>
  );
};

export { DataTable };
