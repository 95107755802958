import { Fragment, useState } from "react";
import { Transition, Menu } from "@headlessui/react";
import {
  ChevronDownIcon,
  GlobeAltIcon,
  ArrowRightOnRectangleIcon,
  DocumentTextIcon,
  TruckIcon,
  Bars3Icon,
  ComputerDesktopIcon,
} from "@heroicons/react/24/outline";

import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { roles } from "utils/constant";
import { Img } from "components/Img";
import { postLogout } from "service/api";
import UseToast from "utils/AfToast";
import { useAuthStore } from "store/auth";
import { GetCommanWay, GetCommanWayMutate } from "API/commonApi";
import { useMutation, useQuery } from "@tanstack/react-query";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const [userDetails, setUserDetails] = useState<any>({});

  const { setToken, setUser, token, removeAll } = useAuthStore();
  const navigate = useNavigate();

  const [perms, setPerms] = useState<any>([]);

  const {} = useQuery(["deliverySubbranchAPI"], () => GetCommanWay("/admin/user/me", {}, {}), {
    keepPreviousData: true,
    onSuccess(data) {
      console.log(data);
      if (data.status == "SUCCESS") {
        setUser(data?.data);
        setUserDetails(data?.data);
        if(data.data.adminRole){
          mutate({
            url: "/admin/adminrole/" + data.data.adminRole,
          });
        }
      }
    },
  });

  const { mutate, isLoading: isloading } = useMutation(GetCommanWayMutate, {
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        setPerms(data?.data.permList.split(",") || []);
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const logout = () => {
    postLogout({ data: {}, headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        UseToast("Logout Successfull", "success");
        localStorage.removeItem("af_token");
        removeAll();
        navigate("/login");
      })
      .catch((err) => {
        console.error(err);
        UseToast("Login Failed! Try Again", "error");
      });
  };

  /*React.useEffect(() => {
    const userD = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : {};
    if (Object.keys(userD).length) {
      setUserDetails(userD.state.user);
      console.log("userD.state", userD.state.user);
    }
  }, []);*/

  const navigation = [
    {
      name: "Dashboard",
      icon: ComputerDesktopIcon,
      current: false,
      to: "/",
    },
    {
      name: "Category",
      icon: Bars3Icon,
      current: false,
      children: [
        { name: "Main Category", to: "/master/category/list" },
        { name: "Sub Category", to: "/master/subcategory/list" },
      ],
      permName: "CAT"
    },
    {
      name: "News",
      icon: Bars3Icon,
      current: false,
      to: "/master/news/list",
      permName: "NEWS"
    },
    {
      name: "Img Ads",
      icon: Bars3Icon,
      current: false,
      to: "/master/ads/list",
      permName: "IMG_AD"
    },
    {
      name: "Video Ads",
      icon: Bars3Icon,
      current: false,
      to: "/master2/ads/list",
      permName: "VID_AD"
    },
    {
      name: "Users",
      icon: Bars3Icon,
      current: false,
      permName: "USER",
      children: [
        { name: "Users", to: "/master/user/list" },
        { name: "Admins", to: "/master/admin/list" },
      ],
    },
    {
      name: "Role",
      icon: Bars3Icon,
      current: false,
      permName: "ROLE",
      children: [{ name: "Role", to: "/master/adminrole/list" }],
    },
  ];

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const location: any = useLocation();
  const params = useParams();
  const id: string | undefined = params?.id;

  let pathName = location?.pathname;
  try {
    let id_: any = location?.state?.state;
    if (!id_) {
      id_ = location?.state?.id;
    }
    if (id_) {
      pathName = pathName.replaceAll("/edit/" + id_, "");
      pathName = pathName.replaceAll("/" + id_, "");
    }
  } catch (ignore) {}

  const sideNavigation = navigation.map((item) => {
    return (
      <div key={"main-" + item.name}>
        {!item.children ? (
          <div key={item.name} className="border-b-2 border-gray-300" hidden={userDetails.adminRole && item.permName && !perms.includes(item.permName)}>
            <NavLink
              to={item?.to}
              onClick={() => setSidebarOpen(false)}
              key={"navlink" + item.name}
              className={(props) => {
                return classNames(props?.isActive && "bg-grey_bg", " w-full flex pl-2 pr-1 py-3 text-left font-medium text-gray-800 ");
              }}
            >
              {item.name}
            </NavLink>
          </div>
        ) : (
          <div className="border-b-2 border-gray-300" hidden={userDetails.adminRole && item.permName && !perms.includes(item.permName)}>
            {item.children.map((subItem) => {
              return (
                <NavLink
                  key={"subnav-" + subItem.name}
                  className={`${
                    false ? "bg-dark1 text-white" : "text-gray-900"
                  } w-full flex pl-2 pr-1 py-3 text-left font-medium text-gray-800 `}
                  to={subItem.to}
                >
                  {subItem.name}
                </NavLink>
              );
            })}
          </div>
        )}
      </div>
    );
  });

  return (
    <div key={"Asd"}>
      {/* responsive */}
      <div className="w-52 bg-[#FFFCF1] min-h-[100vh] p-2 flex border-r border-primary">
        <div className="bg-gray-20-0 border-2 border-primary rounded-md flex-1">
          <div className="flex flex-shrink-0 items-center px-4 py-4 border-b-2 border-primary">
            <Link to="/dashboard">
              <Img src="/logo192.png" className="w-14 mx-auto mb-3" />
              <Img src="/logo.png" className="invert w-40" />
              <h2 className="text-center font-medium tracking-widest text-[#12263F]">ADMIN</h2>
            </Link>
          </div>

          <nav className="space-y-1 bg-white- px-2 overflow-y-visible" aria-label="Sidebar">
            {sideNavigation}
          </nav>
          <button onClick={() => logout()} className={"w-full flex pl-2 ml-2 pr-1 py-3 text-left font-medium text-gray-800"}>
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}
