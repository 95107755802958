import React from "react";

import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { PostLoginResponseType, postLogin } from "service/api";
import * as yup from "yup";

import { Button, Img, Input, Text } from "components";

import useForm from "hooks/useForm";

import "react-toastify/dist/ReactToastify.css";
import { useAuthStore } from "store/auth";
import UseToast from "utils/AfToast";

export const Login: React.FC = () => {
  const { setToken, setUser, token } = useAuthStore();
  const [login, setLogin] = React.useState<PostLoginResponseType>();

  const [isPassVisible, setIsPassVisible] = React.useState<boolean>(false);

  const formValidationSchema = yup.object().shape({
    username: yup.string().required("Username is required"),
    password: yup.string().required("Password is required"),
  });
  const form = useForm(
    { username: "", password: "" },
    {
      validate: true,
      validateSchema: formValidationSchema,
      validationOnChange: true,
    }
  );
  const navigate = useNavigate();

  function login1(data: Partial<{ username: string; password: string }>) {
    const req = {
      data: { username: data?.username, password: data?.password },
    };

    postLogin(req)
      .then((res) => {
        try{
          if(res?.data?.data.userType == 3){
            UseToast("You don't have permission to perform this action !!!", "error")
            return;
          }
        }catch(c){}
        setLogin(res?.data);

        setToken(res?.data?.data?.token);
        setUser(res?.data?.data);

        localStorage.setItem(
          "af_token",
          JSON.stringify(res?.data?.data?.token)
        );

        navigate("/dashboard");
      })
      .catch((err) => {
        console.error(err);
        UseToast("Login Failed! Try Again", "error");
      });
  }

  return (
    <>
      <div className="bg-gray-50 flex flex-col font-gilroy items-center justify-start mx-auto p-[150px] md:px-10 sm:px-5 w-full">
        <div className="flex flex-col gap-[30px] items-center justify-start mb-[18px] w-[45%] md:w-full">
          <div className="flex flex-col md:gap-10 gap-[62px] items-center justify-start w-full">
            
            <div className="flex flex-col gap-6 items-center justify-start w-full">
              <Text
                className="md:text-3xl sm:text-[28px] text-[32px] text-blue_gray-900"
                size="txtGilroySemiBold32"
              >
                Sign in to NewsAudible Admin
              </Text>
              <div className="flex flex-col gap-4 items-center justify-start w-full">
                <div className="flex flex-col gap-1 items-start justify-start w-[512px] sm:w-full">
                  <div className="flex flex-col items-start justify-end pr-1 py-1 w-full">
                    <Text
                      className="text-blue_gray-900 text-lg"
                      size="txtGilroyMedium18"
                    >
                      Email
                    </Text>
                  </div>
                  <Input
                    name="email"
                    placeholder="jane@gmail.com"
                    className="font-medium md:h-auto p-0 placeholder:text-blue_gray-300 sm:h-auto sm:pr-5 text-base text-blue_gray-300 text-left w-full"
                    wrapClassName="bg-white-A700 border border-blue_gray-100 border-solid p-3 rounded-lg w-full"
                    type="text"
                    onChange={(e) => {
                      form.handleChange("username", e);
                    }}
                    errors={form?.errors?.username}
                    value={form?.values?.username}
                  ></Input>
                </div>
                <div className="flex flex-col gap-1 items-start justify-start w-[512px] sm:w-full">
                  <div className="flex flex-col items-start justify-end pr-1 py-1 w-full">
                    <Text
                      className="text-blue_gray-900 text-lg"
                      size="txtGilroyMedium18"
                    >
                      Password
                    </Text>
                  </div>
                  <div className="flex flex-col gap-[15px] items-end justify-start mt-1 w-full">
                    <Input
                      name="InputField"
                      placeholder="Enter Password"
                      className="font-medium p-0 placeholder:text-blue_gray-300 text-base text-blue_gray-300 text-left w-full"
                      wrapClassName="bg-white-A700 border border-blue_gray-100 border-solid flex pl-3 py-3 rounded-lg w-[512px] sm:w-full"
                      type={isPassVisible ? "text" : "password"}
                      onChange={(e) => {
                        form.handleChange("password", e);
                      }}
                      errors={form?.errors?.password}
                      value={form?.values?.password}
                      suffix={
                        <Img
                          className="h-5 ml-[35px] mr-3 cursor-pointer hover:invert"
                          src="images/img_eye.svg"
                          alt="eye"
                          onClick={() => {setIsPassVisible(!isPassVisible)}}
                        />
                      }
                    ></Input>
                    
                  </div>
                </div>
                <Button
                  className="common-pointer bg-blue-A700 cursor-pointer font-medium py-[15px] rounded-md text-base text-center text-white-A700 w-[512px]"
                  onClick={() => {
                    form.handleSubmit(login1);
                  }}
                >
                  Log in
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
