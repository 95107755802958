import React, { useCallback, useEffect, useState } from "react";
import { BrachColumnData, BranchInitialValues, BranchValidationSchema } from "utils/TableColumnData";
import { GeneralAddForm } from "components/GeneralAddForm/GeneralAddForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { object, string } from "yup";
import Select from "react-select";
import { CreateCommanWay, ListCommanWay, UpdateCommanWay } from "API/commonApi";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import UseToast from "utils/AfToast";
import { debounce } from "lodash";
import Spinner from "utils/Spinner";

const NewsCreate: React.FC<any> = ({ mode }) => {
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;
  const location = useLocation();
  const { state }: any = location;
  console.log(state);

  const navigate = useNavigate();
  const params = useParams();
  const [newsId, setNewsId] = useState<number>(Number(params?.id) || 0);
  const [dataMode, setDataMode] = useState<string>(state?.mode ? state?.mode : mode);
  const [isDisabled, setisDisabled] = useState<boolean>(dataMode == "view" ? true : false);

  const [mainCategoryList, setMainCategoryList] = useState<any>([]);
  const [subCategoryList, setSubCategoryList] = useState<any>([]);

  const [newsData, setNewsData] = useState<any>({});
  const [searchData, setSearchData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [imageFile, setImageFile] = useState<any>();
  const [videoFile, setVideoFile] = useState<any>();

  const [fileUploading, setFileUploading] = useState<boolean>(false);
  useEffect(() => {
    setisDisabled(dataMode == "view");
    if (dataMode != "add") {
      refetch();
    }
  }, [dataMode]);

  const newsTypeOpts: any[] = [
    { value: false, label: "Regular News" },
    { value: true, label: "Season" },
  ];

  const getSelectedNewsOpt = (val) => {
    let ans = { value: false, label: "Regular News" };
    newsTypeOpts.forEach((x) => {
      if (x.value == val) {
        ans = x;
      }
    });
    return ans;
  };

  const [langOpts, setlangOpts] = useState<any>([{ value: "", label: "Loading....", id: "" }]);
  /*const langOpts: any[] = [
    { value: "ENGLISH", label: "ENGLISH" },
    { value: "HINDI", label: "HINDI" },
    { value: "GUJARATI", label: "GUJARATI" },
  ];*/
  const getSelectedLangOpt = (val) => {
    console.log("getSelectedLangOpt", val);
    let ans = { value: val, label: val, id: val };
    if (!val) {
      ans = { value: "", label: "Select Language", id: "" };
    }
    /*langOpts.forEach((x) => {
      if (x.value == val) {
        ans = x;
      }
    });*/
    return ans;
  };

  const { refetch: langfetch } = useQuery(["LangListApi"], () => ListCommanWay("/admin/lang/list", {}, {}), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        setlangOpts(data.data.data || []);
      }
    },
  });

  const { refetch: mainCategoryRefetch } = useQuery(
    ["MainCategoryListApi"],
    () =>
      ListCommanWay(
        "/admin/category/list",
        {
          lang: values.lang?.value,
        },
        { paginate: 10000 }
      ),
    {
      keepPreviousData: true,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          let opt = [];
          data.data.data.forEach((p) => {
            opt.push({
              value: p.id,
              label: p.name,
            });
          });
          setMainCategoryList(opt);
        }
      },
    }
  );

  const { refetch: subCatRefetch } = useQuery(
    ["SubCategoryListApi"],
    () =>
      ListCommanWay(
        "/admin/subcategory/list",
        {
          category: values.mainCategory?.value,
        },
        { paginate: 10000 }
      ),
    {
      keepPreviousData: true,
      enabled: false,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          let opt = [];
          data.data.data.forEach((p) => {
            opt.push({
              value: p.id,
              label: p.name,
            });
          });
          setSubCategoryList(opt);
        }
      },
    }
  );

  const { refetch } = useQuery(
    ["NewsData"],
    () =>
      ListCommanWay(
        "/admin/news/list",
        {
          id: newsId,
        },
        { paginate: 10000, include: ["_mainCategory", "_subCategory"] }
      ),
    {
      keepPreviousData: true,
      enabled: false,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          console.log(data.data.data[0]);
          setNewsData(data.data.data[0]);
        }
      },
    }
  );

  const { refetch: newListRefetch, isLoading: isNewsSearchLoading } = useQuery(
    ["AddEditNewsListData"],
    () =>
      ListCommanWay(
        "/admin/news/list",
        {
          title: { $like: `%${searchTerm}%` },
        },
        { paginate: 10, include: ["_subCategory"] }
      ),
    {
      keepPreviousData: true,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          setSearchData(data.data.data);
        }
      },
    }
  );
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log("sending ajax term  ", searchTerm);
      // Send Axios request here
      newListRefetch();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    defaultValues();
  }, [newsData]);

  const successNavigate = useCallback(
    debounce(() => navigate("/master/news/list"), 200),
    []
  );
  const {
    mutate,
    isLoading: isloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(dataMode == "add" ? CreateCommanWay : UpdateCommanWay, {
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        UseToast("Data Added Successfully", "success");
        successNavigate();
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const { handleSubmit, handleBlur, values, handleChange, errors, setTouched, touched, dirty, setFieldValue } = useFormik({
    validationSchema: object().shape({
      title: string().required("Required Field"),
      image: string().required("Required Field"),
      mainCategory: object().required("Reqired Field"),
    }),
    initialValues: {
      title: "",
      description: "",
      image: "",
      video: "",
      mainCategory: { value: 0, label: "Select Main Category" },
      subCategory: { value: 0, label: "Select Category" },
      isSeason: getSelectedNewsOpt(false),
      lang: { value: "", label: "loading..." },
      innerNews: [],
    },
    onSubmit: async (values, { resetForm }) => {
      console.log(values, imageFile, videoFile);
      let _values: any = { ...values };

      const url = BASE_URL + "/admin/upload";
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      setFileUploading(true);
      let formData: any;

      if (videoFile) {
        formData = new FormData();
        formData.append("files", videoFile);
        formData.append("folder", "video");
        const response2 = await axios.post(url, formData, config);
        if (response2?.data?.uploadSuccess?.length > 0) {
          _values.video = response2.data.uploadSuccess[0].path;
        } else {
          try {
            alert(response2.data.uploadFailed[0].error);
          } catch (c) {
            alert("Video Uploadd Error");
          }
          setFileUploading(false);
          return;
          delete _values.video;
        }
      } else {
        delete _values.video;
      }

      if (imageFile) {
        formData = new FormData();
        formData.append("files", imageFile);
        formData.append("folder", "image");
        const response = await axios.post(url, formData, config);
        if (response?.data?.uploadSuccess?.length > 0) {
          _values.image = response.data.uploadSuccess[0].path;
        } else {
          delete _values.image;
        }
      } else {
        delete _values.image;
      }

      _values.mainCategory = values.mainCategory?.value;
      _values.subCategory = values.subCategory?.value;

      let innerNewsAry = [];
      _values.innerNews.forEach((x) => {
        innerNewsAry.push(x.id);
      });
      _values.innerNews = innerNewsAry.join(",");
      _values.isSeason = _values.isSeason.value;
      _values.lang = _values.lang.value;

      if(dataMode == "add" && !videoFile){
        alert("Please Select Video");
        setFileUploading(false);
        return
      }
      
      mutate({
        url: "/admin/news" + (dataMode == "add" ? "/create" : "/update/" + params?.id),
        data: _values,
      });
      setFileUploading(false);
    },
  });
  const defaultValues = () => {
    setFieldValue("lang", getSelectedLangOpt(newsData?.lang));
    setFieldValue("title", newsData?.title);
    setFieldValue("description", newsData?.description);
    setFieldValue("image", newsData?.image);
    setFieldValue("video", newsData?.video);
    setFieldValue("isSeason", getSelectedNewsOpt(newsData?.isSeason));
    //setFieldValue("innerNews", newsData?.innerNews);
    setFieldValue("mainCategory", {
      value: newsData?._mainCategory?.id,
      label: newsData?._mainCategory?.name,
    });
    setFieldValue("subCategory", {
      value: newsData?._subCategory?.id,
      label: newsData?._subCategory?.name,
    });
    setTouched({});
  };

  useEffect(() => {
    if (values.mainCategory) {
      if (values.mainCategory?.value != 0) {
        setTimeout((x) => {
          subCatRefetch();
        }, 100);
      }
    }
  }, [values.mainCategory]);

  useEffect(() => {
    if (values.lang) {
      setTimeout((x) => {
        mainCategoryRefetch();
      }, 100);
    }
  }, [values.lang]);

  return (
    <>
      {(isloading || fileUploading) && <Spinner />}

      <div className="m-6">
        <div className="text-right">
          {dataMode == "view" ? (
            <button
              onClick={() => {
                setDataMode("Edit");
              }}
              className="px-4 py-2 text-sm rounded-md font-semibold bg-warning border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
            >
              Edit Details
            </button>
          ) : (
            <button
              onClick={() => {
                setDataMode("view");
              }}
              className="px-4 py-2 text-sm rounded-md font-semibold bg-warning border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
            >
              Cancel Edit
            </button>
          )}
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          <div>
            <label htmlFor={"title"} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Title
            </label>
            <input
              type="text"
              id={"title"}
              className={`${isDisabled ? "bg-gray-300" : ""} bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
            focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
              placeholder="Title"
              onBlur={handleBlur}
              onChange={handleChange}
              name="title"
              value={values.title}
              disabled={isDisabled}
            />
            <p className="mt-21 text-sm text-red-600">{touched.title && Boolean(errors.title) ? errors.title : ""}</p>
          </div>

          <div className="mt-3">
            <label htmlFor={"lang"} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Language
            </label>
            <Select
              className={`${isDisabled ? "bg-gray-300" : "bg-gray-50"} border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
              id={"lang"}
              options={langOpts}
              onChange={(el) => {
                setFieldValue("mainCategory", { value: 0, label: "Select Main Category" });
                setFieldValue("subCategory", { value: 0, label: "Select Category" });
                setFieldValue("lang", el);
              }}
              isDisabled={isDisabled}
              name="lang"
              value={values.lang}
            />
            <p className="mt-21 text-sm text-red-600">{touched.lang && Boolean(errors.lang) ? errors.lang : ""}</p>
          </div>

          <div className="mt-3">
            <label htmlFor={"mainCategory"} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Main Category
            </label>
            <Select
              className={`${isDisabled ? "bg-gray-300" : "bg-gray-50"} border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
              id={"mainCategory"}
              options={mainCategoryList}
              onChange={(el) => {
                setFieldValue("subCategory", { value: 0, label: "Select Category" });
                setFieldValue("mainCategory", el);
              }}
              isDisabled={isDisabled}
              name="mainCategory"
              value={values.mainCategory}
            />
            <p className="mt-21 text-sm text-red-600">{touched.mainCategory && Boolean(errors.mainCategory) ? errors.mainCategory : ""}</p>
          </div>

          <div className="mt-3">
            <label htmlFor={"subCategory"} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Sub Category
            </label>
            <Select
              className={`${isDisabled ? "bg-gray-300" : "bg-gray-50"} border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
              id={"subCategory"}
              options={subCategoryList}
              onChange={(el) => {
                setFieldValue("subCategory", el);
              }}
              isDisabled={isDisabled}
              name="subCategory"
              value={values.subCategory}
            />
            <p className="mt-21 text-sm text-red-600">{touched.subCategory && Boolean(errors.subCategory) ? errors.subCategory : ""}</p>
          </div>

          <div className="mt-3">
            <label htmlFor={"subCategory"} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              News Type
            </label>
            <Select
              className={`${isDisabled ? "bg-gray-300" : "bg-gray-50"} border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
              id={"isSeason"}
              options={newsTypeOpts}
              onChange={(el) => {
                setFieldValue("isSeason", el);
              }}
              isDisabled={isDisabled}
              name="isSeason"
              value={values.isSeason}
            />
            <p className="mt-21 text-sm text-red-600">{touched.isSeason && Boolean(errors.isSeason) ? errors.isSeason : ""}</p>
          </div>

          <div className="mt-3 border-y border-black-900 py-3" hidden={!values.isSeason?.value}>
            <label htmlFor={"-"} className="block mb-2 mt-2 text-sm font-medium text-gray-900 dark:text-white">
              Select News For Season
            </label>
            <Select
              className={`${isDisabled ? "bg-gray-300" : "bg-gray-50"} border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
              id={"innerNews"}
              options={isNewsSearchLoading ? [{ title: "Loading...", id: 0 }] : searchData}
              getOptionLabel={(e: any) => {
                return e.title;
              }}
              getOptionValue={(e: any) => {
                return e.id;
              }}
              isSearchable={true}
              isMulti={true}
              onChange={(el) => {
                console.log(el);
                setFieldValue("innerNews", el);
              }}
              isDisabled={isDisabled}
              name="innerNews"
              value={values.innerNews}
              closeMenuOnSelect={false}
              onInputChange={(item, ip) => {
                console.log(item, ip);
                setSearchTerm(item || "");
              }}
              isLoading={isNewsSearchLoading}
            />
            <p className="mt-21 text-sm text-red-600">{touched.innerNews && Boolean(errors.innerNews) ? errors.innerNews : ""}</p>
          </div>

          <div className="mt-3" hidden={values?.isSeason?.value}>
            <label htmlFor={"description"} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Description
            </label>
            <textarea
              id={"description"}
              className={`${isDisabled ? "bg-gray-300" : ""} bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
            focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
              placeholder="description"
              onBlur={handleBlur}
              onChange={handleChange}
              name="description"
              value={values.description}
              disabled={isDisabled}
            />
            <p className="mt-21 text-sm text-red-600">{touched.description && Boolean(errors.description) ? errors.description : ""}</p>
          </div>

          <div className="mt-3">
            <img src={BASE_URL + "" + (values.image ? values.image : "/placeholder.png")} alt="IMG" style={{ width: 200 }} />

            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">
              Select Thumbnail
            </label>
            <input
              className="text-sm text-stone-500
              file:mr-5 file:py-1 file:px-3 file:border-[1px]
              file:text-xs file:font-medium
              file:bg-stone-50 file:text-stone-700
              hover:file:cursor-pointer hover:file:bg-blue-50
              hover:file:text-blue-700"
              id="image"
              name="iamge"
              type="file"
              onChange={(e) => {
                setFieldValue("image", "selected");
                setImageFile(e.target.files[0]);
              }}
              disabled={isDisabled}
            />
          </div>

          <div hidden={values?.isSeason?.value}>
            <video src={BASE_URL + "" + values.video} style={{ width: 200 }} />

            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="video_input">
              Select Video
            </label>
            <input
              className="text-sm text-stone-500
              file:mr-5 file:py-1 file:px-3 file:border-[1px]
              file:text-xs file:font-medium
              file:bg-stone-50 file:text-stone-700
              hover:file:cursor-pointer hover:file:bg-blue-50
              hover:file:text-blue-700"
              id="video_input"
              type="file"
              onChange={(e) => {
                setVideoFile(e.target.files[0]);
              }}
              disabled={isDisabled}
            />
          </div>

          {dataMode != "view" && (
            <div className="inline-flex rounded-md overflow-hidden shadow-sm mb-1 mt-4" role="group">
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
              >
                {dataMode.toUpperCase()}
              </button>
              <button
                type="button"
                onClick={() => {}}
                className="px-4 py-2 text-sm font-medium text-white bg-gray-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
              >
                Back to List
              </button>
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default NewsCreate;
