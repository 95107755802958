import React from "react";
import AppContent from "../components/AppContent/AppContent";
import Navbar from "components/Navbar/Navbar";

export function DefaultLayout() {
  return (
    <>
      <section>
        <div className="relative flex">
          <Navbar />

          <div className="flex-1">
            {/* <div className="bg-background_grey h-screen"> */}
            <div className="bg-background_grey height-class">
              <AppContent />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
