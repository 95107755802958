import React, { useCallback, useEffect, useState } from "react";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { CreateCommanWay, GetCommanWay, ListCommanWay, UpdateCommanWay } from "API/commonApi";
import { AfDataType as ADT } from "utils/TableColumnData";
import { useFormik } from "formik";
import axios, { AxiosResponse } from "axios";
import UseToast from "utils/AfToast";
import { debounce } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { object, string } from "yup";
import { Switch } from "@headlessui/react";

export type AfTableProps = Partial<{
  mode: string;
}>;

export const RoleAddEdit: React.FC<AfTableProps> = ({ mode = "View" }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { state }: any = location;

  const [dataMode, setDataMode] = useState<string>(state?.mode ? state?.mode : mode);
  type OptionType = {
    value: number;
    label: string;
  };
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  const defaultOptions: any = [{ value: 0, label: "Loading..." }];
  const [nestedTables, setNestedTables] = useState<any>({});
  const [nestedSelectedOptions, setNestedSelectedOptions] = useState<any>({});

  const [CAT_check, set_CAT_check] = useState<any>(false);
  const [NEWS_check, set_NEWS_check] = useState<any>(false);
  const [IMG_AD_check, set_IMG_AD_check] = useState<any>(false);
  const [VID_AD_check, set_VID_AD_check] = useState<any>(false);

  useEffect(() => {
    console.log(dataMode);
    if (dataMode == "View" || dataMode == "Edit") {
      const id: string | undefined = params?.id;
      console.log(id);

      let includes_ = [];
      ListCommanWay("/admin/adminrole/list", { id: id }, { include: includes_ }).then((x) => {
        if (x.status == "SUCCESS") {
          x.data = x.data.data[0];
          console.log(x.data);
          let vals = {};
          vals["name"] = x.data.name;
          setFieldValue("name", x.data.name);

          let perms = x.data.permList.split(",") || [];
          if(perms.includes("CAT")){
            set_CAT_check(true);
          }
          if(perms.includes("NEWS")){
            set_NEWS_check(true);
          }
          if(perms.includes("IMG_AD")){
            set_IMG_AD_check(true);
          }
          if(perms.includes("VID_AD")){
            set_VID_AD_check(true);
          }
        }
      });
    }
  }, [dataMode]);

  const {
    mutate,
    isLoading: isloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(dataMode == "Add" ? CreateCommanWay : UpdateCommanWay, {
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        UseToast("Data Added Successfully", "success");
        successNavigate();
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });
  const { handleSubmit, handleBlur, values, handleChange, errors, setValues, setErrors, touched, dirty, setFieldValue } = useFormik({
    validationSchema: object().shape({
      name: string().required("Required Field"),
    }),
    initialValues: {
      name: "",
    },
    onSubmit: async (values, { resetForm }) => {
      let _values: any = { ...values };
      let isFailed: any = false;

      if (isFailed) {
        UseToast(isFailed, "error");
        return;
      }

      let perms = [];
      if (CAT_check) {
        perms.push("CAT");
      }
      if (NEWS_check) {
        perms.push("NEWS");
      }
      if (IMG_AD_check) {
        perms.push("IMG_AD");
      }
      if (VID_AD_check) {
        perms.push("VID_AD");
      }
      _values.permList = perms.join(",");

      mutate({
        url: "/admin/adminrole" + (dataMode == "Add" ? "/create" : "/update/" + params?.id),
        data: _values,
      });
    },
  });

  const successNavigate = useCallback(
    debounce(() => navigate("/master/adminrole/list"), 200),
    []
  );

  const onCancel = () => {
    navigate("/master/adminrole/list");
  };

  return (
    <>
      <div className="p-5">
        <div className="text-right">
          {dataMode == "View" ? (
            <button
              onClick={() => {
                setDataMode("Edit");
              }}
              type="submit"
              className="px-4 py-2 text-sm rounded-md font-semibold bg-warning border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
            >
              Edit Details
            </button>
          ) : (
            <button
              onClick={() => {
                setDataMode("View");
              }}
              type="submit"
              className="px-4 py-2 text-sm rounded-md font-semibold bg-warning border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
            >
              Cancel Edit
            </button>
          )}
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          <div className="grid grid-cols-3 gap-4">
            <div>
              <label htmlFor={"name"} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Role Name
              </label>
              <input
                type="text"
                id={"name"}
                className={`${
                  isloading || dataMode == "View" ? "bg-gray-300" : ""
                } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                placeholder={"Name"}
                onBlur={handleBlur}
                onChange={handleChange}
                name={"name"}
                value={values.name}
                disabled={isloading || dataMode == "View"}
              />

              <p className="mt-21 text-sm text-red-600">{touched.name && Boolean(errors.name) ? errors.name : ""}</p>
            </div>
          </div>

          <div className="grid grid-cols-1 mt-4 gap-4">
            <div className="flex items-center gap-2">
              <Switch
                checked={CAT_check}
                onChange={set_CAT_check}
                id="CAT_check"
                disabled={dataMode != "Edit" && dataMode != "Add"}
                className={`${CAT_check ? "bg-blue-600" : "bg-gray-200"} relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only"></span>
                <span
                  className={`${
                    CAT_check ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
              <label htmlFor="CAT_check">Manage Category</label>
            </div>

            <div className="flex items-center gap-2">
              <Switch
                checked={NEWS_check}
                onChange={set_NEWS_check}
                id="NEWS_check"
                disabled={dataMode != "Edit" && dataMode != "Add"}
                className={`${NEWS_check ? "bg-blue-600" : "bg-gray-200"} relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only"></span>
                <span
                  className={`${
                    NEWS_check ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
              <label htmlFor="NEWS_check">Manage News</label>
            </div>

            <div className="flex items-center gap-2">
              <Switch
                checked={IMG_AD_check}
                onChange={set_IMG_AD_check}
                id="IMG_AD_check"
                disabled={dataMode != "Edit" && dataMode != "Add"}
                className={`${IMG_AD_check ? "bg-blue-600" : "bg-gray-200"} relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only"></span>
                <span
                  className={`${
                    IMG_AD_check ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
              <label htmlFor="IMG_AD_check">Manage Image Ads</label>
            </div>

            <div className="flex items-center gap-2">
              <Switch
                id="VID_AD_check"
                checked={VID_AD_check}
                onChange={set_VID_AD_check}
                disabled={dataMode != "Edit" && dataMode != "Add"}
                className={`${VID_AD_check ? "bg-blue-600" : "bg-gray-200"} relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only"></span>
                <span
                  className={`${
                    VID_AD_check ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
              <label htmlFor="VID_AD_check">Manage Video Ads</label>
            </div>
          </div>

          {dataMode != "View" && (
            <div className="inline-flex rounded-md overflow-hidden shadow-sm mb-1 mt-4" role="group">
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
              >
                {dataMode}
              </button>
              <button
                type="button"
                onClick={onCancel}
                className="px-4 py-2 text-sm font-medium text-white bg-gray-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
              >
                Back to List
              </button>
            </div>
          )}
        </form>
      </div>
    </>
  );
};
